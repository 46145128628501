import React, { Component, Fragment, Suspense } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Badge, Collapse, Tooltip, OverlayTrigger, ProgressBar } from 'react-bootstrap';

// import VisibilitySensor from 'react-visibility-sensor';
import ReactGA4 from 'react-ga4';
// import ReactToPrint from 'react-to-print';
import slugify from 'slugify';

import config from '../../../../config.js';
import { getFile } from '../../../../util/file.js';
import { capitalizeFirstLetter, truncateToChar } from '../../../../util/text.js';

import { getProduct, getProductPrice, getProductSlug, getProductOption, getProductsFromStore, getFilteredProducts, getProductSpecialPrice, getProductSpecialPriceDateThreshold, memoizedGetProducts } from '../../ProductActions.js';
import { getCartVouchers, formatAnalyticsItems, getCartQuantityStep } from '../../../Cart/CartActions.js';
import { getPanelBySlugAndLanguage, getContentBlockItemsByName, getContentBlockItemValue, getCmsPageSeoDescription, memoizedGetPanelBySlugAndLanguage } from '../../../Cms/CmsActions.js';
import { getUserGroup, getUserGroupOption, USER_GROUP_DEFAULT } from '../../../User/UserGroupActions.js';
import { getCurrentCurrency, defaultCurrency } from '../../../Currency/CurrencyActions.js';
import { getTranslation, getLanguagePath, getDefaultLanguage } from '../../../Intl/IntlHelper.js';
import { getReferral } from '../../../App/AppActions.js';

import Icon from '../../../../components/Content/Icon.js';
import ErrorPage from '../../../Error/pages/ErrorPage.js';
import withRouter from '../../../../components/Router/WithRouter.js';
import Breadcrumbs from '../../../App/components/view/Breadcrumbs.js';
import FileLoader from '../../../../components/Content/FileLoader.js';
import LazyLoader from '../../../../components/Content/LazyLoader.js';
import HtmlComponent from '../../../../components/Text/HtmlComponent.js';
// import Slider from '../../../../components/Content/Slider.js';
import Price from '../../../Currency/components/view/Price.js';
import CmsPanel from '../../../Cms/components/content/CmsPanel.js';
import ProductCapacityPrice from '../../components/view/ProductCapacityPrice.js';
import ProductWeight from '../../components/view/ProductWeight.js';
import ProductSnippet from '../../components/view/ProductSnippet.js';
// import ProductPrint from '../../components/view/ProductPrint.js';
import ProductListItem from '../../components/list/ProductListItem.js';
import CartItemQuantitySelector from '../../../Cart/components/form/CartItemQuantitySelector.js';
import ReviewSummary from '../../../Review/components/view/ReviewSummary.js';
import PreFooter from '../../../App/components/PreFooter.js';
import SocialShare from '../../../../components/Social/SocialShare.js';
import VoucherProgress from '../../../Voucher/components/view/VoucherProgress.js';
import ProductRelatedPosts from '../../components/view/ProductRelatedPosts.js';
import Chrono from '../../../../components/Time/Chrono.js';
import ProductSeo from '../../components/view/ProductSeo.js';

class ProductViewPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMounted: false,
            mainPicture: '',
            // displayVideo: false,
            detailsTab: 'description',
            variation: null,
        };
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     return this.props.page !== nextProps.page || this.props.product !== nextProps.product;
    // }

    componentDidMount() {
        if(this.props.product) {
            // this.initMainPicture();
            this.initVariation();
            this.handleTrackAnalytics();
        }
        this.setState({
            isMounted: true,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.product !== this.props.product) {
            // this.initMainPicture();
            this.initVariation();
            this.handleTrackAnalytics();
        }
    }

	getProducts() {
		return this.props.products;
	}

    getCurrentProduct() {
        if((this.props.product || {}).isVariation) {
            // Redirect to main product page with variation id in query param
            const variationFrom = this.getProducts().find(product => (product.variations || []).find(variation => variation.productId === this.props.product._id));
            variationFrom && this.props.navigate(getLanguagePath(`${getProductSlug(variationFrom)}?variation=${this.props.product._id}`, this.props.intl.locale));
        }
        return {
            ...this.props.product,
            ...(this.state.variation || {}),
        };
    }

    getTranslation(field) {
        return getTranslation(this.getCurrentProduct(), field, this.props.intl.locale);
    }

    getDiscount() {
        const { userGroup, referral } = this.props;
        const product = this.getCurrentProduct();
        const displayPriceHT = getUserGroupOption(userGroup, 'displayPriceHT');
        return 100 - Math.round((getProductPrice(product, 1, userGroup, !displayPriceHT, referral) * 100) / product[displayPriceHT ? 'priceHT' : 'priceTTC']);
    }

    getAssociatedProducts() {
		const associatedProductsCount = 6;
        const { userGroup } = this.props;
        let associatedProducts = [];
        const associatedProductsIds = (getContentBlockItemsByName(this.props.page.blocks, 'associated-products') || []).map(item => item.text);
        if(associatedProductsIds && associatedProductsIds.length > 0) {
            associatedProducts = associatedProductsIds.map(id => this.getProducts().find(product => product._id === id)).filter(product => product);
        }

        if(associatedProducts.length === associatedProductsCount) {
            return associatedProducts;
        }

        return [
            ...(associatedProducts || []),
            ...(this.getProducts() || [])
                .filter(product => (
					(product.isAvailable && !product.isVariation && !product.isPack)
					&& (!(getProductOption(product, 'visibleGroups') || []).length || getProductOption(product, 'visibleGroups').includes((userGroup || {}).identifier))
					&& (
						getTranslation(product, 'categories', this.props.intl.locale).filter(category => this.getTranslation('categories').includes(category)).length > 1
						|| getTranslation(product, 'categories', getDefaultLanguage()).filter(category => getTranslation(this.props.product, 'categories', getDefaultLanguage()).includes(category)).length > 1
					)
					&& product._id !== this.props.product._id
				))
				.sort((pA, pB) => (getTranslation(pA, 'categories', this.props.intl.locale).sort((cA, cB) => cA.localeCompare(cB)).join('-').toLowerCase() === getTranslation(pB, 'categories', this.props.intl.locale).sort((cA, cB) => cA.localeCompare(cB)).join('-').toLowerCase() ? 1 : -1))
                .slice(0, associatedProductsCount - associatedProducts.length),
        ];
    }

    // handleToggleDetailsTab = tab => {
    //     this.setState({
    //         detailsTab: tab,
    //     });
    // }

    // initMainPicture = () => {
    //     let product = this.getCurrentProduct();
    //     if(!(product.pictures || [])[0]) {
    //         product = this.props.product;
    //     }
    //     this.setState({
    //         mainPicture: (product.pictures || []).find(picture => picture.includes('.gif') || picture.includes('.webm') || picture.includes('.mp4')) || product.pictures[0],
    //     });
    // };

    initVariation = () => {
        if(this.props.search.get('variation')) {
            this.setState({
                variation: this.getProducts().find(product => product._id === this.props.search.get('variation')),
            });
        }
    };

    handleSetMainPicture = event => this.setState({ mainPicture: event.target.getAttribute('data-path') }, () => window.scrollTo({ top: 0, left: 0, behavior: 'instant' }));

    handleOpenCompositionTab = () => this.setState({ detailsTab: 'composition' });

    handleSetVariation = variationId => {
        this.setState({
            variation: this.getProducts().find(product => product._id === variationId),
        });
    };

    handleTrackAnalytics = () => {
        const { currency, userGroup, referral } = this.props;
        const product = this.getCurrentProduct();
        const productPrice = getProductPrice(product, 1, userGroup, !getUserGroupOption(userGroup, 'displayPriceHT'), referral);
        if(product && typeof window !== 'undefined') {
            if(config.google.analytics.trackingCodeGA4) {
                // GA: New
                ReactGA4.event('view_item', {
                    currency,
                    value: productPrice,
                    items: formatAnalyticsItems([{ ...product, price: productPrice, quantity: 1 }]),
                });
            }

            if(window.dataLayer) {
                window.dataLayer.push({ ecomm_prodid: (product.slug || product._id).toLowerCase() });
                window.dataLayer.push({ ecomm_pagetype: 'product' });
                window.dataLayer.push({ ecomm_totalvalue: productPrice });
            }

            if(typeof window !== 'undefined' && window.fbq) {
                window.fbq('track', 'ViewContent', {
                    content_name: product.name,
                    content_category: 'Keesbo',
                    content_ids: [product.slug],
                    content_type: 'product',
                    value: productPrice,
                    currency: 'EUR',
                });
            }
        }
    };

    isAvailable() {
        return this.props.product.isAvailable && (!this.props.userGroup || !(getProductOption(this.props.product, 'visibleGroups') || []).length || getProductOption(this.props.product, 'visibleGroups').includes((this.props.userGroup || {}).identifier));
    }

    renderAttributes() {
        const product = this.getCurrentProduct();
        if((product.attributes || []).length || product.brand) {
            return (
                <table border="0" className="w-100">
                    <tbody>
                        {this.renderAttribute({
                            name: 'brand',
                            value: product.brand,
                        })}
                        {(product.attributes || []).sort((attributeA, attributeB) => attributeA.sort - attributeB.sort).map(attribute => {
                            return this.renderAttribute({
                                ...attribute,
                                ...((attribute.translations || []).find(translation => translation.lang === this.props.intl.locale) || {}),
                            });
                        })}
                        {this.renderAttribute({
                            name: 'weight',
                            value: product.weight,
                            display: <ProductWeight product={product} hideLabel />,
                        })}
                    </tbody>
                </table>
            );
        }
    }

    renderAttribute(attribute) {
        return (
            <tr key={attribute.id}>
                <th className="pe-2" style={{ fontWeight: 'normal' }}><FormattedMessage id={`productAttribute${capitalizeFirstLetter(attribute.name)}`} defaultMessage={capitalizeFirstLetter(attribute.name)} />:</th>
                <td><strong>{this.renderAttributeValue(attribute)}</strong></td>
            </tr>
        );
    }

    renderAttributeValue(attribute) {
        if(attribute.name === 'dryIndex') {
            const value = parseInt(attribute.value, 10);
            // return (
            //     <div>{Array(value).fill(1).map((i, index) => <Icon key={index} icon="add" />)}</div>
            // );
            return (
                <OverlayTrigger
                    overlay={
                        <Tooltip id={attribute.name}>
                            <FormattedMessage id={`productAttribute${capitalizeFirstLetter(attribute.name)}${value || 1}`} />
                        </Tooltip>
                    }
                >
                    <ProgressBar variant={value > 3 ? 'dangerlight' : 'light'} label={`${value}/5`} now={value} max={5} className="text-black" />
                </OverlayTrigger>
            );
        }
		if(attribute.name === 'weight') {
			return attribute.display || attribute.value;
		}
        return attribute.value && <CmsPanel slug={`product-attribute-${slugify(attribute.name || '').toLowerCase()}-${slugify(attribute.value || '').toLowerCase()}`} defaultDisplay={attribute.display || attribute.value} />;
    }

    renderCategories() {
        return this.getTranslation('categories').sort().map((category, index) => {
            return (
                <span key={category}><Badge bg="light" className="h6 text-black">{capitalizeFirstLetter(category)}</Badge>{' '}</span>
            );
        });
    }

    renderDiscountBadge() {
        if(this.getDiscount() > 0) {
            return (
                <span className="position-absolute d-inline-block float-end ms-3" style={{ top: '5px', right: '5px' }}>
                    <Badge bg="danger" pill className="px-2 py-3"><span className="display-6"><FormattedMessage id="productDiscountPercent" defaultMessage="{discount}% off" values={{ discount: this.getDiscount() }} /></span></Badge>
                </span>
            );
        }
    }

    renderMainPicture() {
        let product = this.getCurrentProduct();
        if(!(product.pictures || [])[0]) {
            product = this.props.product;
        }
        const mainPicture = this.state.mainPicture
            || (false && this.state.isMounted && (product.pictures || []).find(picture => picture.includes('.gif') || picture.includes('.webm') || picture.includes('.mp4')))
            || product.pictures[0];

		const imageAlt = this.props.page.title || getTranslation(product, 'subtitle', this.props.intl.locale) || getTranslation(product, 'name', this.props.intl.locale);

        if(mainPicture) {
            const isVideo = mainPicture.includes('.gif') || mainPicture.includes('.webm') || mainPicture.includes('.mp4');
            const image = isVideo ? (product.pictures || []).find(picture => picture !== mainPicture) : mainPicture;
            let sizes = {};
            if(!this.state.mainPicture) {
                sizes = getProductOption(product, 'mainPictureOrientation') === 'portrait' ? { width: 400, height: 600 } : { width: 600, height: 400 };
            }
            return (
                <div className="position-relative mx-2 mx-md-0 rounded bg-light product-image-wrapper">
                    {
                        isVideo
                        ? (
                            <div style={{ maxHeight: '360px', overflow: 'hidden' }}>
                                <FileLoader filename={mainPicture} useAppUrl autoPlay loop muted alt={imageAlt} className="w-100 rounded" />
                            </div>
                        ) : (
                            <picture>
                                <source srcSet={getFile(mainPicture, { format: 'webp' })} width={sizes.width && `${sizes.width / 2}px`} height={sizes.height && `${sizes.height / 2}px`} type="image/webp" />
                                <source media="(min-width: 800px)" type="image/jpeg" srcSet={getFile(mainPicture)} width={sizes.width && `${sizes.width}px`} height={sizes.height && `${sizes.height}px`} />
                                <img src={getFile(mainPicture)} alt={imageAlt} width={sizes.width && `${sizes.width / 2}px`} height={sizes.height && `${sizes.height / 2}px`} className="w-100 rounded shadow" />
                            </picture>
                        )
                    }
                    {this.renderDiscountBadge()}
                </div>
            );
        }
    }

    renderSlider(isMobileView = false) {
        let product = this.getCurrentProduct();

        if(!product.pictures || !product.pictures.length) {
            product = this.props.product;
        }

		const pictures = (product.pictures || []).filter((path, index) => (!this.state.mainPicture && index) || (this.state.mainPicture && path !== this.state.mainPicture));
        if(pictures.length > 1) {
            // const slides = [];
            // const picturesTemp = [...pictures];
            // while(picturesTemp.length > 0) {
            //     slides.push(picturesTemp.splice(0, 3));
            // }

            // <Slider
            //     slides={slides.map((slide, index) => ({
            //         content: (
            //             <Row key={index} className="gx-1">
            //                 {slide.map(path => (
            //                     <Col key={path} xs="6">
            //                         <FileLoader filename={path} useAppUrl autoPlay loop muted alt={this.getTranslation('subtitle') || this.getTranslation('name')} className="w-100 rounded" data-path={path} onClick={this.handleSetMainPicture} />
            //                     </Col>
            //                 ))}
            //             </Row>
            //         ),
            //     }))}
            //     disableLightbox
            //     label={this.getTranslation('name')}
            // />
            return (
                <div>
                    <div className={classnames('mb-3', isMobileView ? ['d-block', 'd-md-none'] : ['d-none', 'd-md-block'])}>
                        {isMobileView && <span className="d-flex justify-content-between h6 mb-1 pt-3 border-top text-uppercase"><FormattedMessage id="productPicturesMore" defaultMessage="More pictures" /> <Icon icon="chevron-down" /></span>}
                        <div className={classnames('overflow-hidden', 'bg-info', 'p-1', 'rounded')} style={{ overflow: 'hidden', height: pictures.length > 0 ? (isMobileView ? `${70 / Math.ceil(pictures.length / 3)}px` : `${100 / Math.ceil(pictures.length / 3)}px`) : '0px' }}>
                            <Row className="gx-1">
                                {pictures.map((path, index) => (
                                    <Col key={`${path}-${index}`} xs={isMobileView > 1 ? 6 : 4} className="text-center">
										<FileLoader filename={path} useAppUrl autoPlay loop muted alt={this.getTranslation('subtitle') || this.getTranslation('name')} className="mw-100 rounded see-picture" height={isMobileView ? '70px' : '100px'} loading="lazy" data-path={path} onClick={this.handleSetMainPicture} />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        {(pictures.length > 0 || !isMobileView) && <small><em><FormattedMessage id="productPicturesClickToDetail" defaultMessage="Click on the image to enlarge" /></em></small>}
                    </div>
                </div>
            );
        }
    }

    renderPrice() {
		const product = this.getCurrentProduct();
        const displayPriceHT = getUserGroupOption(this.props.userGroup, 'displayPriceHT');
        const productPrice = getProductPrice(product, 1, this.props.userGroup, !displayPriceHT, this.props.referral);
		const productSpecialPrice = getProductSpecialPrice(product, displayPriceHT ? 'priceHT' : 'priceTTC', this.props.userGroup, this.props.referral);
        return (
            <Fragment>
                <div className="">
                    {productPrice > 0 && productPrice < product[displayPriceHT ? 'priceHT' : 'priceTTC'] && <span className="h4 mb-0 me-1 text-black-70 text-crossed"><Price amount={product[displayPriceHT ? 'priceHT' : 'priceTTC']} formatPrice /></span>}
                    <span className="display-5 m-0 p-0 text-danger">
                        <strong><Price amount={productPrice} formatPrice /></strong>
                    </span>
                </div>
                <small className="d-block text-uppercase text-black-50">
                    {displayPriceHT ? <FormattedMessage id="productUnitPriceExclTax" defaultMessage="Unit price excl. tax" /> : <FormattedMessage id="productUnitPriceInclTax" defaultMessage="Unit price incl. tax" />}
                </small>
				{this.state.isMounted && productSpecialPrice && getProductSpecialPriceDateThreshold(productSpecialPrice) && (
					<strong className="text-danger">
						<Icon icon="danger" /> <FormattedMessage id="productSpecialPriceDateThresholdLimit" defaultMessage="Offer ends in {chrono}" values={{ chrono: <Chrono date={getProductSpecialPriceDateThreshold(productSpecialPrice)} displayText /> }} />
					</strong>
				)}
            </Fragment>
        );
    }

    renderShareButton() {
        // const seo = this.getProductSeo();
		const product = this.getCurrentProduct();
        return (
            <SocialShare
				title={product.name}
				image={getFile(product.pictures[0]) || ''}
				text={getCmsPageSeoDescription(this.props.page, getTranslation(product, 'description', this.props.intl.locale)) || ''}
            />
        );
        // <ButtonGroup size="sm">
        // </ButtonGroup>
        //     <ReactToPrint
        //         trigger={() => {
        //             // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
        //             // to the root node of the returned component as it will be overwritten.
        //             return <Button variant="dark" outline tag="a" href="#" className="m-1 p-2 rounded"><Icon icon="printer" size="lg" /></Button>;
        //         }}
        //         content={() => this.productPrintRef}
        //         onBeforeGetContent={() => this.setState({ isPrintMode: true })}
        //     />
        //     <div className="d-none">
        //          <ProductPrint ref={elt => { this.productPrintRef = elt; }} product={product} products={this.getProducts()} userGroup={this.props.userGroup} referral={this.props.referral} />
        //     </div>
        // return null;
    }

    renderGroupPanel() {
        const renderGroupPanelContent = () => {
            const groupIdentifier = (this.props.userGroup || {}).identifier || USER_GROUP_DEFAULT;
            switch(groupIdentifier) {
                case 'pro':
                case 'semi-pro':
                case 'mini-pro':
                case 'ce':
                case 'asso':
					return <CmsPanel slug={`product-view-group-${groupIdentifier}`} />;

                case USER_GROUP_DEFAULT:
                default:
					return <CmsPanel slug="product-view-group-individual" />;
            }
        };
        return (
            <div className="my-3 pt-2 border-top" style={{ fontSize: '0.8rem' }}>
                <Row>
                    <Col xs="2" className="p-3 pt-4 text-center text-light">
                        <Icon icon="info" size="2x" />
                    </Col>
                    <Col>
                        {renderGroupPanelContent()}
                    </Col>
                </Row>
            </div>
        );
    }

    renderDiscountPanel() {
        return (
            <VoucherProgress />
        );

        // const { userGroup, vouchers, intl } = this.props;
        // if((vouchers || []).length > 0 && vouchers[0]) {
        //     return (
        //         <Alert variant="danger" className="rounded text-lg progress-bar-striped border-5 border-end-0 border-top-0 border-bottom-0">
        //             <Row>
        //                 <Col xs="12" md="3" className="text-center">
        //                     <span className="display-3">🎁</span>
        //                 </Col>
        //                 <Col>
        //                     <HtmlComponent text={getTranslation(vouchers[0], 'description', intl.locale)} /><br />
        //                 </Col>
        //             </Row>
        //         </Alert>
        //     );
        // }
        // return ((userGroup || {}).identifier === USER_GROUP_DEFAULT) && <CmsPanel slug="discount-offers" />;
    }

    renderAssociatedProducts() {
        return this.getAssociatedProducts().length > 0 && (
            <div className="pt-3 pb-0 py-md-5 bg-info bg-texture">
                <Container className="px-2 px-md-0">
                    <div className="mb-3 text-center">
                        <span className="h2 d-inline-block border-half-danger text-center"><FormattedMessage id="productAssociatedTitle" defaultMessage="You will also like" /></span>
                    </div>
                    <Row>
                        {this.getAssociatedProducts().map((associatedProduct, index) => (
                            <Col xs="12" md="4" key={`${associatedProduct._id}-${index}`}>
                                <ProductListItem product={associatedProduct} />
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        );
    }

    render() {
        const { userGroup, page, products, referral, intl } = this.props;
        const product = this.getCurrentProduct();
        // <Helmet {...this.getSeo()} />
        const title = getContentBlockItemValue(page.blocks, 'title') || this.getTranslation('name');
        const description = getContentBlockItemValue(page.blocks, 'description') || this.getTranslation('description');
        const composition = getContentBlockItemValue(page.blocks, 'composition') || this.getTranslation('composition');
        const shippingInfoSup = getContentBlockItemValue(page.blocks, 'shipping-info-sup');
        const recommandation = getContentBlockItemValue(page.blocks, 'recommandation');
        // const voucherInfo = getContentBlockItemByName(page.blocks, 'voucher-info');
        const breadcrumbs = getContentBlockItemsByName(page.blocks, 'breadcrumbs');

        if(this.props.product && product) {
            return (
                <Fragment>
					<ProductSeo product={product} page={page} />
                    <ProductSnippet product={product} />
                    <Container className="page-wrapper">
                        <Row className="">
                            <Col xs="12" md="6" className="position-relative mb-2">
                                <Breadcrumbs crumbs={[...breadcrumbs, !breadcrumbs.length && title]} />
                                {this.renderMainPicture()}

                                {this.isAvailable() && (
                                    <Fragment>
                                        {this.renderSlider()}
                                        <div className="d-none d-md-block">
                                            {this.renderShareButton()}
                                        </div>

                                        <div className="d-none d-md-block p-2 m-2">
                                            {getProductOption(product, 'mainPictureOrientation') !== 'portrait' && <ReviewSummary hideReviews hideLogo hideTitle />}
                                            {this.renderDiscountPanel()}
                                        </div>
                                    </Fragment>
                                )}
                            </Col>
                            <Col>
                                <div className="px-2 px-md-0 pb-3">
                                    {title && <h1 className="mt-1 mt-lg-4 mb-2 mb-md-0 text-dark text-center text-md-start">{title}</h1>}
                                    <Row>
                                        <Col xs="6" className="text-center text-md-start">
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={
                                                    <Tooltip id={`product-weight-${product._id}`}>
                                                        <CmsPanel slug="product-weight-information" />
                                                    </Tooltip>
                                                }
                                            >
                                                <strong className="h5"><ProductWeight product={product} hideLabel /> <small><Icon icon="info" id={`product-weight-${product._id}`} className="text-light" /></small></strong>
                                            </OverlayTrigger>
                                            <br />
                                            <ProductCapacityPrice product={product} />
                                            <br />
                                            {getCartQuantityStep(product, userGroup) > 1 && <div className="pt-2"><Icon icon="cube" /> <FormattedMessage id="productPacking" defaultMessage="Box of {count} unit(s)" values={{ count: getCartQuantityStep(product, userGroup) }} /></div>}

                                            {product.isPack && (
                                                <div className="text-start">
                                                    {product.items.map(item => (
                                                        <span key={item.productId} className="d-block mt-1 py-1 border-top">
                                                            <strong className="h6 text-secondary">{item.quantity}</strong> x {getTranslation((products.find(product => item.productId === product._id) || {}), 'name', intl.locale)}
                                                        </span>
                                                    ))}
                                                </div>
                                            )}
                                        </Col>
                                        <Col xs="6" className="text-center text-md-end">
                                            {this.renderPrice()}
                                        </Col>
                                    </Row>

                                    <div className="my-3">
                                        {this.isAvailable() ? <CartItemQuantitySelector product={this.props.product} variation={this.state.variation} onChangeVariation={this.handleSetVariation} /> : <strong className="d-block h6 text-danger text-center text-md-end"><FormattedMessage id="productNotAvailable" defaultMessage="Not available" /></strong>}
                                    </div>

                                    <HtmlComponent text={description} useCollapse collapsedSize={getProductOption(product, 'mainPictureOrientation') === 'portrait' && 20} />

                                    <Row className="my-2">
                                        <Col xs="12" lg="6" className="mb-2 mb-lg-0">
                                            {this.renderAttributes()}
                                        </Col>
                                        <Col xs="12" lg="6" className="text-center text-lg-end">
                                            {this.renderCategories()}
                                        </Col>
                                    </Row>

                                    {this.isAvailable() && this.renderSlider(true)}

                                    {composition && (
                                        <Fragment>
                                            <strong aria-controls="product-composition" aria-expanded={this.state.detailsTab === 'composition'} onClick={this.handleOpenCompositionTab} className="d-flex justify-content-between h6 mb-0 py-3 border-top text-uppercase see-composition"><FormattedMessage id="productComposition" defaultMessage="Composition" /> <Icon icon="chevron-right" /></strong>
                                            <Collapse in={this.state.detailsTab === 'composition'}>
                                                <div id="product-composition">
                                                    <HtmlComponent text={composition} />
                                                </div>
                                            </Collapse>
                                        </Fragment>
                                    )}

                                    <hr />

                                    {this.isAvailable() && (
                                        <Fragment>
                                            {shippingInfoSup && <HtmlComponent text={shippingInfoSup} />}

                                            {((userGroup || {}).identifier === USER_GROUP_DEFAULT) && <CmsPanel slug="shipping-information" />}

                                            <div className="d-block d-md-none">
                                                <ReviewSummary hideReviews hideLogo hideTitle />
                                            </div>

                                            {getProductOption(product, 'mainPictureOrientation') === 'portrait' && (
                                                <div className="d-none d-md-block">
                                                    <ReviewSummary hideReviews hideLogo hideTitle />
                                                </div>
                                            )}

                                            <div className="d-block d-md-none">
                                                {this.renderShareButton()}
                                            </div>

											{this.renderGroupPanel()}

                                            <div className="d-block d-md-none p-2 m-2 border-top">
                                                {this.renderDiscountPanel()}

                                                <div className="mt-2 pt-2  text-center border-top">
                                                    <h3>{this.getTranslation('name')}</h3>
                                                    {this.isAvailable() && <CartItemQuantitySelector product={this.props.product} variation={this.state.variation} disableQuantityEdition disableExtraMessages onChangeVariation={this.handleSetVariation} />}
                                                    {this.renderPrice()}
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    {!this.isAvailable() && this.renderAssociatedProducts()}

                    <div className="critical-css-limiter">
                        {recommandation ? <CmsPanel slug={recommandation} /> : <ProductRelatedPosts products={[product]} categories={this.getTranslation('categories')} excerptSize={30} count={4} />}
                    </div>

                    {this.isAvailable() && this.renderAssociatedProducts()}

                    <ReviewSummary product={product} hideLogo />

                    {this.isAvailable() && (
                        <div className="bg-info bg-texture">
                            <Container className="my-3 py-3 px-md-0">
                                <Row className="pt-3">
                                    <Col xs="12" md="6" className="position-relative">
										{this.renderMainPicture()}
                                    </Col>
                                    <Col>
                                        <div className="px-2 px-md-0 pb-3">
                                            {title && <span className="mb-2 mb-md-0 mt-2 mt-md-n2 h1 text-dark text-center text-md-start">{title}</span>}
                                            <Row>
                                                <Col xs="6">
                                                    <ProductWeight product={product} /><br />
                                                    {((userGroup || {}).conditions || []).some(condition => condition.key === 'packFill') && <FormattedMessage id="productPacking" defaultMessage="Box of {count} unit(s)" values={{ count: product.packing }} />}
                                                </Col>
                                                <Col className="text-end">
                                                    {this.renderPrice()}
                                                </Col>
                                            </Row>

                                            <div>
                                                <CartItemQuantitySelector product={product} onChangeVariation={this.handleSetVariation} disableQuantityEdition buttonColor="danger" />
                                            </div>

                                            <HtmlComponent text={`${truncateToChar(description, '.')}...`} />

                                            {((userGroup || {}).identifier === USER_GROUP_DEFAULT) && <CmsPanel slug="shipping-information" />}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    )}

                    <div className="bg-texture">
                        <PreFooter />
                    </div>

                </Fragment>
            );
        }
        return <ErrorPage />;
    }
}

function mapStateToProps(store, props) {
    const product = props.product || getProduct(store, getContentBlockItemValue(props.page.blocks, 'product')) || null;
    return {
        currency: getCurrentCurrency(store),
        userGroup: getUserGroup(store),
        product,
        products: memoizedGetProducts(store, { isActive: true }),
        vouchers: getCartVouchers(store),
        siteTitlePanel: memoizedGetPanelBySlugAndLanguage(store, 'site-title', (store.intl || {}).locale),
        referral: getReferral(store),
    };
}

ProductViewPage.defaultProps = {
    currency: defaultCurrency,
    product: null,
    userGroup: null,
    products: [],
    vouchers: [],
    siteTitlePanel: null,
    referral: null,
};

ProductViewPage.propTypes = {
    page: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    currency: PropTypes.string,
    product: PropTypes.object,
    userGroup: PropTypes.object,
    products: PropTypes.arrayOf(PropTypes.object),
    vouchers: PropTypes.arrayOf(PropTypes.object),
    siteTitlePanel: PropTypes.object,
    referral: PropTypes.string,
};

export default connect(mapStateToProps)(withRouter(injectIntl(ProductViewPage)));
