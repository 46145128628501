/**
 * Client entry point
 */

// import 'regenerator-runtime/runtime';
// import 'core-js/stable';

// Polyfills
import 'promise-polyfill/src/polyfill';

import React, { StrictMode } from 'react';
// import ReactDOM, { render } from 'react-dom';
import ReactDOMClient, { createRoot } from 'react-dom/client';

import App from './App.js';
import { configureStore } from './store.js';

// PWA Offline support: https://github.com/NekR/offline-plugin
import * as OfflinePluginRuntime from '@lcdp/offline-plugin/runtime';
import { initPersistedData } from './modules/App/AppActions.js';
import config from './config.js';

// import { setAppLoaded, setAppSSRMode } from './modules/App/AppActions.js';

// INIT
process.env.NODE_ENV === 'production' && OfflinePluginRuntime.install();

console.log(window.origin, `Should ${config.url.includes(window.origin) ? '' : 'not '}hydrate`);

// Initialize store
const store = configureStore(window.__INITIAL_STATE__);
// Allow the passed state to be garbage-collected

// delete window.__INITIAL_STATE__; // Perf leak ??

const container = document.getElementById('root');

// Set as not SSR
// store.dispatch(setAppSSRMode(false))

store.dispatch(initPersistedData());

// console.log('Index::: Hydrate');
(config.url.includes(window.origin) || process.env.NODE_ENV === 'development') && ReactDOMClient.hydrateRoot(container, (
	// <StrictMode>
		<App store={store} />
	// </StrictMode>
));



// For hot reloading of react components
if(module.hot) {
    module.hot.accept('./App', () => {
		const root = createRoot(container);
        const NextApp = require('./App').default; // eslint-disable-line global-require
        // ReactDOMClient.hydrateRoot(container, <NextApp store={store} />);
		root.render(<NextApp store={store} />)
    });
}


// Block react-intl errors
if(process.env.NODE_ENV !== 'production') {
    const originalConsoleError = console.error
    if(console.error === originalConsoleError) {
        console.error = (...args) => {
            if(`${args[0]?.message}`.indexOf('[@formatjs/intl') === 0) {
                return;
            }
            originalConsoleError.call(console, ...args)
        }
    }
}
