import React, { Component, Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Card, Badge, Button } from 'react-bootstrap';
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from 'react-router-dom';
import Icon from '../../../../components/Content/Icon.js';

import withRouter from '../../../../components/Router/WithRouter.js';

import { getFile } from '../../../../util/file.js';
import { truncate, stripTags } from '../../../../util/text.js';
import { isMobile } from '../../../../util/browser.js';

import { getProductSlug, getProductPrice, getProductOption, getProductSpecialPrice, getProductSpecialPriceDateThreshold } from '../../ProductActions.js';
import { getUserGroup, getUserGroupOption } from '../../../User/UserGroupActions.js';
import { getTranslation } from '../../../Intl/IntlHelper.js';
import { getReferral } from '../../../App/AppActions.js';

import FileLoader from '../../../../components/Content/FileLoader.js';
import LazyLoader from '../../../../components/Content/LazyLoader.js';
import HtmlComponent from '../../../../components/Text/HtmlComponent.js';
import ProductWeight from '../view/ProductWeight.js';
import ProductAddToCart from '../form/ProductAddToCart.js';
import Price from '../../../Currency/components/view/Price.js';
import PredictiveLink from '../../../Cms/components/view/PredictiveLink.js';
import Chrono from '../../../../components/Time/Chrono.js';

function ProductListItem({ navigate, intl, product, userGroup = null, isOrder = false, hideAddToCart = false, viewType = 'grid', referral = null }) {
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true);
	}, []);

    const displayPriceHT = getUserGroupOption(userGroup, 'displayPriceHT');
    const productPrice = getProductPrice(product, 1, userGroup, !displayPriceHT, referral);
	const productSpecialPrice = getProductSpecialPrice(product, displayPriceHT ? 'priceHT' : 'priceTTC', userGroup, referral);

    const [activePicture, setActivePicture] = useState(null);

    const getDiscount = () => {
        return 100 - Math.round((productPrice * 100) / product[displayPriceHT ? 'priceHT' : 'priceTTC']);
    };

    const renderBenefits = () => {
        return getTranslation(product, 'benefits', intl.locale).sort().map((benefit, index) => {
            return (
                <span key={benefit}><Badge bg="danger">{benefit}</Badge>{' '}</span>
            );
        });
    };

    const getProductUrl = () => `/${intl.locale}/${getProductSlug(product, intl.locale)}`;

    const viewProduct = () => {
        navigate(getProductUrl());
    };

    const { locale } = intl;

    const renderPicture = () => {
        const pictures = product.pictures || [];
        const video = pictures.find(picture => picture.includes('.gif') || picture.includes('.webm') || picture.includes('.mp4'));
        const image = pictures.find(picture => picture !== video);

        if(video) {
            if(mounted && isMobile('screen')) {
                return (
                    <div className="product-image-wrapper" style={{ maxHeight: '220px', overflow: 'hidden' }}>
                        <VisibilitySensor active={!activePicture} onChange={visible => visible && setActivePicture(video)}>
                            {activePicture ? <FileLoader filename={activePicture} useAppUrl poster={getFile(image)} autoPlay loop muted loadonmount className="card-img mt-n1" style={{ maxWidth: '100%' }} /> : <span>{'&nbsp;'}</span>}
                        </VisibilitySensor>
                    </div>
                );
            }

            if(activePicture) {
                return (
                    <div className="product-image-wrapper" style={{ maxHeight: '220px', overflow: 'hidden' }}>
                        <FileLoader filename={activePicture} useAppUrl poster={getFile(image)} autoPlay loop muted loadonmount className="card-img" style={{ maxWidth: '100%' }} onMouseOut={() => process.env.NODE_ENV === 'production' && setActivePicture(null)} />
                    </div>
                );
            }
        }

        return (
            <div className="product-image-wrapper" style={{ height: '220px', overflow: 'hidden' }}>
                {pictures.length > 0 && (
                    <img src={getFile(product.pictures[0])} alt={getTranslation(product, 'name', locale)} className={classnames('card-img', getProductOption(product, 'mainPictureOrientation') === 'portrait' ? 'mt-n8' : 'mt-n1')} style={{ maxWidth: '100%' }} onMouseOver={() => video && setActivePicture(video)} loading="lazy" />
                )}
                {/* <span className="icon-box"><Icon icon="add" /></span> */}
            </div>
        );
    };

    const renderPrice = () => {
        return (
            <Fragment>
                <small className="d-none"><FormattedMessage id="productUnitPrice" defaultMessage="Unit price" /></small>
                {productPrice > 0 && productPrice < product[displayPriceHT ? 'priceHT' : 'priceTTC'] && <small className="d-inline-block text-lg me-1 text-black-75 text-crossed"><Price amount={product[displayPriceHT ? 'priceHT' : 'priceTTC']} formatPrice /></small>}
                <span className="h3 mb-0 text-danger"><Price amount={productPrice} formatPrice /></span><br />
                {displayPriceHT && <small className="text-black-75"><em><FormattedMessage id="productPriceHT" defaultMessage="Excl. tax" /></em></small>}
            </Fragment>
        );
    };

    const renderDiscount = () => {
        return productPrice > 0 && productPrice < product[displayPriceHT ? 'priceHT' : 'priceTTC'] && (
			<Fragment>
				<div className="h3" style={{ position: 'absolute', top: '5px', right: '5px' }}>
					<Badge bg="danger" pill className="px-2 py-3">-{getDiscount()}%</Badge>
				</div>
				{mounted && productSpecialPrice && getProductSpecialPriceDateThreshold(productSpecialPrice) && (
					<span className="position-absolute d-block text-danger text-end p-1 rounded-5 bg-light bg-opacity-75 font-weight-bold" style={{ top: '20px', left: '10px' }}>
						<Icon icon="danger" /> <FormattedMessage id="productSpecialPriceDateThresholdLimit" defaultMessage="Offer ends in {chrono}" values={{ chrono: <Chrono date={getProductSpecialPriceDateThreshold(productSpecialPrice)} /> }} />
					</span>
				)}
			</Fragment>
        );
    };

    const renderLinks = () => {
        return (
            <Fragment>
                {!hideAddToCart && (product.isAvailable ? <ProductAddToCart product={product} color="outline-secondary" className="w-100" /> : <Button variant="link" className="w-100 m-0 p-0 mt-n2 border-0 text-danger"><FormattedMessage id="productNotAvailable" defaultMessage="Not available" /></Button>)}
                {/* <Button as="a" href={getProductUrl()} variant="outline-link" className="w-100 mt-0 p-1 border-0 text-dark"><FormattedMessage id="productView" defaultMessage="View this product" /> <Icon icon="chevron-right" /></Button> */}
            </Fragment>
        );
    };

    if(viewType === 'list') {
		const description = getTranslation(product, 'description', locale);
        return (
            <Card className="mx-2 mx-md-0 mb-4 product-list-item overflow-hidden rounded">
                <Row>
                    <Col xs="12" md="4">
                        {renderPicture()}
                    </Col>
                    <Col>
                        <div className="py-3 px-2 px-md-0">
                            <div className="d-flex d-md-block justify-content-between">
								<strong className="d-block h4 mb-1 text-start" style={{ height: '2em' }}><a href={getProductUrl()} className="product-link">{getTranslation(product, 'name', locale)}</a></strong>
								<small><ProductWeight product={product} /></small>
                            </div>

                            <div className="mt-2">
                                {description && <HtmlComponent text={`${truncate(stripTags(description), 50)}...`} />}
                                {false && <div className="text-center text-md-start"><small className="text-primary"><Icon icon="cube" /> <FormattedMessage id="productPacking" defaultMessage="Box of {count} unit(s)" values={{ count: product.packing }} /></small></div>}
                                <div className="d-block d-md-none mt-3 text-center">
                                    {renderPrice()}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs="12" md="3">
                        <div className="p-md-3">
                            <div className="d-none d-md-block text-center">
                                {renderPrice()}
                                {renderDiscount()}
                            </div>

                            {renderLinks()}
                        </div>
                    </Col>
                </Row>
            </Card>
        );
    }
    if(viewType === 'mini') {
        return (
            <div>
                {product.pictures.length > 0 && (
					<div className="card-img" style={{ height: '120px', overflow: 'hidden' }}>
						<img src={getFile(product.pictures[0])} alt={getTranslation(product, 'subtitle', locale) || getTranslation(product, 'name', locale)} style={{ maxWidth: '100%' }} loading="lazy" />
					</div>
                )}
                <h4 className="h6 mt-2"><a href={getProductUrl()} className="product-link">{getTranslation(product, 'name', locale)}</a></h4>
                <div className="d-none d-md-block my-1 text-center">
                    {productPrice > 0 && productPrice < product[displayPriceHT ? 'priceHT' : 'priceTTC'] && <small className="text-lg me-1 text-black-75 text-crossed"><Price amount={product[displayPriceHT ? 'priceHT' : 'priceTTC']} formatPrice />{' '}</small>}
                    <span className="h4 mb-0 my-1 text-danger"><Price amount={productPrice} formatPrice /></span>
                    {displayPriceHT && <small className="text-black-75"><em><FormattedMessage id="productPriceHT" defaultMessage="Excl. tax" /></em></small>}
                </div>
                {!hideAddToCart && (product.isAvailable ? <ProductAddToCart product={product} variant="link" size="sm" className="d-block text-center m-0 mt-1 p-0" /> : <Button variant="link" className="w-100 m-0 p-0 mt-n2 border-0 text-danger"><FormattedMessage id="productNotAvailable" defaultMessage="Not available" /></Button>)}
            </div>
        );
    }
    // <FileLoader filename={product.pictures[0]} alt={getTranslation(product, 'name', locale)} className="card-img" style={{ maxWidth: '100%' }} />
    return (
        <Card className={classnames('product-list-item', isOrder ? 'me-3' : 'mb-4', 'shadow', 'overflow-hidden', 'rounded')}>
            {renderPicture()}
            <Card.Body className={classnames(false && product.isHighlight && 'bg-secondary')} style={{ }}>
                <div className="d-flex justify-content-between">
                    <div style={{ minHeight: '2.5em' }}>
                        <h4 className="d-block h5 mb-0 pb-0 text-start"><a href={getProductUrl()} className="product-link">{getTranslation(product, 'name', locale)}</a></h4>
                        <small className="h6 font-weight-bold text-black-75"><ProductWeight product={product} /></small>
                    </div>
                    <div className="d-block text-center">
                        {renderPrice()}
                    </div>
                </div>

                {renderDiscount()}

                <div className="mt-2">
                    {renderLinks()}
                </div>
            </Card.Body>
        </Card>
    );
}
// <Button variant="secondary" size="sm" outline block><Icon icon="eye" /></Button>

function mapStateToProps(store, props) {
    return {
        userGroup: getUserGroup(store),
        referral: getReferral(store),
    };
}

ProductListItem.propTypes = {
    navigate: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    userGroup: PropTypes.object,
    product: PropTypes.object.isRequired,
    viewType: PropTypes.string,
    isOrder: PropTypes.bool,
    hideAddToCart: PropTypes.bool,
    referral: PropTypes.string,
};

export default connect(mapStateToProps)(withRouter(injectIntl(ProductListItem)));
