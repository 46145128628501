import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getUserGroup } from '../../../User/UserGroupActions';
import { getProductSpecialPriceValue, memoizedGetProducts } from '../../ProductActions';
import { getReferral } from '../../../App/AppActions';

import ProductList from './ProductList';

export function ProductHiglightList({ userGroup, products, referral = '' }) {
	return (
		<ProductList
			products={products.filter(product => {
				console.log(product.name, getProductSpecialPriceValue(product, 'priceTTC', userGroup, referral), product.isHighlight);
				return getProductSpecialPriceValue(product, 'priceTTC', userGroup, referral) || product.isHighlight;
			}).slice(0, 9)}
			hideSorter
			itemsPerPage={0}
		/>
	);
}

ProductHiglightList.propTypes = {
	userGroup: PropTypes.object.isRequired,
	products: PropTypes.arrayOf(PropTypes.object).isRequired,
	referral: PropTypes.string,
};

// Retrieve data from store as props
function mapStateToProps(store) {
    return {
        userGroup: getUserGroup(store),
        products: memoizedGetProducts(store, { isActive: true, isPack: false, isVariation: false }),
        referral: getReferral(store),
    };
}

export default connect(mapStateToProps)(ProductHiglightList);
